// src/utils/validationSchemas.js;
import * as Yup from "yup";

// **Feature Detection for Unicode Property Escapes**
// Unicode property escapes (e.g., \p{L}) allow matching characters based on Unicode properties.
// They provide a powerful way to include letters from any language in regex patterns.
// However, not all JavaScript environments (especially older browsers) support this feature.
// The following IIFE (Immediately Invoked Function Expression) tests whether the current environment
// supports Unicode property escapes by attempting to create a regex with \p{L} and the 'u' flag.
// If the environment does not support it, a fallback regex is used instead.
const supportsUnicodePropertyEscapes = (() => {
	try {
		// Attempt to create a regex with Unicode property escapes
		new RegExp("\\p{L}", "u");
		return true; // If successful, Unicode property escapes are supported
	} catch (e) {
		return false; // If an error is thrown, fallback regex will be used
	}
})();

const nameRegex = supportsUnicodePropertyEscapes ? /^[\p{L}\s'-]+$/u : /^[A-Za-zÀ-ÿ\s'-]+$/;

export const contactFormSchema = Yup.object().shape({
	name: Yup.string()
		.trim()
		.required("Name is required")
		.matches(nameRegex, "Name should only contain valid letters, spaces, hyphens, and apostrophes")
		.max(100, "Name must not exceed 100 characters"),

	email: Yup.string()
		.trim()
		.email("Invalid email format")
		.required("Email is required")
		.max(254, "Email must not exceed 254 characters"),

	phoneNumber: Yup.string()
		.trim()
		.required("Phone number is required")
		.matches(/^[0-9+\-\s()]+$/, "Phone number should only contain digits, spaces, and the characters: + - ( )"),

	message: Yup.string()
		.trim()
		.required("Message is required")
		.max(500, "Message must not exceed 500 characters")
		.matches(/^[^<>]*$/, "Message must not contain < or > characters"),
});

export const waitlistValidationSchema = Yup.object().shape({
	fullName: Yup.string()
		.trim()
		.required("Name is required")
		.matches(nameRegex, "Name should only contain valid letters, spaces, hyphens, and apostrophes")
		.max(100, "Name must not exceed 100 characters"),
	email: Yup.string()
		.trim()
		.email("Invalid email format")
		.required("Email is required")
		.max(254, "Email must not exceed 254 characters"),
	phoneNumber: Yup.string()
		.trim()
		.required("Phone number is required")
		.matches(/^[0-9+\-\s()]+$/, "Phone number should only contain digits, spaces, and the characters: + - ( )"),
	unitTypes: Yup.array().min(1, "At least one unit type must be selected").required("Unit types are required"),
	preferredMoveInDate: Yup.date().nullable(),
	message: Yup.string()
		.trim()
		.nullable()
		.max(500, "Message must not exceed 500 characters")
		.matches(/^[^<>]*$/, "Message must not contain < or > characters"),
});

export const rentalInquiryValidationSchema = Yup.object().shape({
	fullName: Yup.string()
		.trim()
		.required("Name is required")
		.matches(nameRegex, "Name should only contain valid letters, spaces, hyphens, and apostrophes")
		.max(100, "Name must not exceed 100 characters"),
	email: Yup.string()
		.trim()
		.email("Invalid email format")
		.required("Email is required")
		.max(254, "Email must not exceed 254 characters"),
	phoneNumber: Yup.string()
		.trim()
		.required("Phone number is required")
		.matches(/^[0-9+\-\s()]+$/, "Phone number should only contain digits, spaces, and the characters: + - ( )"),
	suiteInterest: Yup.string().required("Suite interest is required"),
	preferredMoveInDate: Yup.date().nullable(),
	message: Yup.string()
		.trim()
		.nullable()
		.max(500, "Message must not exceed 500 characters")
		.matches(/^[^<>]*$/, "Message must not contain < or > characters"),
});
