// components/layout/Footer.js
import Link from "next/link";
import { useRouter } from "next/router";
import { PhoneIcon, MapPinIcon, EnvelopeIcon } from "@heroicons/react/24/solid";

export default function Footer() {
	const router = useRouter();

	const siteName = process.env.NEXT_PUBLIC_SITE_NAME;

	const linkStyle = (path) => `hover:text-purple ${router.pathname === path ? "text-purple font-semibold" : ""}`;

	return (
		<footer className="bg-white text-black p-6">
			<div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
				<div>
					{/* IMPORTANT - Change this for new client site */}
					<h3 className="font-bold text-xl text-purple mb-4">{siteName}</h3>
					<p className="md:w-4/5">
						Leading the way in student living, our residence offers the best in comfort and style.
					</p>
				</div>
				<div>
					<h3 className="font-bold text-xl text-purple mb-4">Quick Links</h3>
					<ul className="space-y-1.5">
						<li>
							<Link href="/" className={linkStyle("/")}>
								Home
							</Link>
						</li>
						<li>
							<Link href="/suites" className={linkStyle("/suites")}>
								Suites
							</Link>
						</li>
						<li>
							<Link href="/amenities" className={linkStyle("/amenities")}>
								Amenities
							</Link>
						</li>
						<li>
							<Link href="/gallery" className={linkStyle("/gallery")}>
								Gallery
							</Link>
						</li>
						<li>
							<Link href="/location" className={linkStyle("/location")}>
								Location
							</Link>
						</li>
						<li>
							<Link href="/contact" className={linkStyle("/contact")}>
								Contact
							</Link>
						</li>
					</ul>
				</div>
				<div>
					<h3 className="font-bold text-xl text-purple mb-4">Contact Us</h3>
					<div className="space-y-4">
						<a
							href="https://www.google.com/maps/search/?api=1&query=177+St+George+Street,+Toronto,+ON+M5R+2M5"
							target="_blank"
							rel="noopener noreferrer"
							className="flex items-start space-x-2 hover:text-purple"
						>
							<MapPinIcon className="h-5 w-5 mt-1 flex-shrink-0 text-purple" />
							<span>
								177 St. George Street
								<br />
								Toronto, ON M5R 2M5
							</span>
						</a>
						<a href="mailto:info@riverviewresidences.ca" className="flex items-center space-x-2 hover:text-purple">
							<EnvelopeIcon className="h-5 w-5 flex-shrink-0 text-purple" />
							<span>info@riverviewresidences.ca</span>
						</a>
						<a href="tel:+14164567890" className="flex items-center space-x-2 hover:text-purple">
							<PhoneIcon className="h-5 w-5 flex-shrink-0 text-purple" />
							<span>(416) 456-7890</span>
						</a>
					</div>
				</div>
			</div>
			<div className="text-center mt-8 border-t border-gray-300 pt-6">
				<p className="flex flex-col sm:flex-row justify-center items-center text-sm text-gray-500">
					<span>© {new Date().getFullYear()} All Rights Reserved</span>
					<span className="hidden sm:inline mx-2">|</span>
					<span className="mt-2 sm:mt-0">
						Website designed and developed by{" "}
						<a href="https://www.rentiq.com" className="hover:text-purple">
							Rent IQ
						</a>
					</span>
				</p>
			</div>
		</footer>
	);
}
